import { ChevronRightIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import Link from 'next/link'
import React from 'react'
import { overrideTailwindClasses } from 'tailwind-override'
import { focusClasses } from '../utils/css-classes'

type ButtonProps = React.PropsWithChildren<
  JSX.IntrinsicElements['button'] & {
    as?: React.ElementType<any>
    href?: string
    primary?: boolean
    success?: boolean
    arrow?: boolean
    disabled?: boolean
    submitting?: boolean
    fullWidth?: boolean
    small?: boolean
    target?: string
    download?: string
  }
>

export function Button({
  children,
  as: Component = 'button',
  href = null,
  primary = false,
  success = false,
  arrow = false,
  disabled = false,
  submitting = false,
  fullWidth = false,
  small = false,
  className,
  ...props
}: ButtonProps) {
  const classes = overrideTailwindClasses(
    clsx(
      'px-6 py-2 font-semibold truncate border-2 rounded-full text-primary border-primary max-w-xs sm:max-w-none no-underline',
      focusClasses,
      'hover:bg-primary-dark hover:border-primary-dark hover:text-white',
      'inline-flex items-center justify-center gap-x-1',

      primary && 'bg-primary text-white',

      (disabled || submitting) && [
        'border-slate-300 hover:border-slate-300 cursor-not-allowed text-slate-400',
        'hover:bg-transparent hover:text-slate-400',
        (primary || success) && 'bg-slate-300 hover:bg-slate-300',
      ],

      success &&
        !disabled &&
        !submitting &&
        'bg-green-700 border-green-700 text-white hover:bg-green-800 hover:border-green-800',

      small && 'px-4 py-2 mt-2 text-xs',

      fullWidth && 'w-full sm:max-w-xs',

      className
    )
  )

  if (href != null && Component == 'button') {
    Component = Link
  }

  return (
    <Component
      className={classes}
      href={href}
      {...props}
      disabled={disabled || submitting}
    >
      {submitting && (
        <svg
          className="w-5 h-5 mr-2 text-white animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx={12}
            cy={12}
            r={10}
            stroke="currentColor"
            strokeWidth={4}
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}

      {children}
      {arrow && <ChevronRightIcon className="w-5 h-5" />}
    </Component>
  )
}
