import { Wrap } from '@/design-system'
import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { overrideTailwindClasses } from 'tailwind-override'

type SectionProps = {
  className?: string
  dark?: boolean
  children?: ReactNode
}

export const Section = ({ children, dark, className }: SectionProps) => (
  <section
    className={overrideTailwindClasses(
      clsx(dark && 'bg-primary-bg sm:py-4', className)
    )}
  >
    <Wrap>{children}</Wrap>
  </section>
)
