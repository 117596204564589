import { gql, request } from 'graphql-request'
import { env } from 'process'
import React, { useEffect, useRef, useState } from 'react'
import { useInput } from '../utils/hooks'
import { Button } from './Button'
import { Textarea } from './Textarea'

const postFeedback = (vars: any) =>
  fetch('/api/feedback/message', {
    method: 'POST',
    body: JSON.stringify(vars),
  })

const postVote = () =>
  fetch('/api/feedback/vote', {
    method: 'POST',
  })

const Container = ({ children }) => (
  <div className="flex flex-col justify-between gap-4 p-4 pb-8 mt-16 border-t-4 sm:flex-row bg-slate-100 border-primary">
    {children}
  </div>
)
const ErrorLabel = ({ children }) => (
  <div className="w-full p-4 font-bold text-white bg-red-600">{children}</div>
)

type FormProps = {
  onSubmit: Function
  onCancel: React.MouseEventHandler
}

const Form = ({ onSubmit, onCancel }: FormProps) => {
  const areaRef = useRef<HTMLTextAreaElement>(null)
  const { value, bind } = useInput('')

  useEffect(() => {
    areaRef.current?.focus()
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(value)
  }

  return (
    <Container>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col w-full m-auto sm:flex-row"
      >
        <h3 className="hidden m-0 text-base font-semibold sm:block sm:w-1/2">
          Este utilă această pagină?
        </h3>

        <div className="flex flex-col gap-2 sm:w-1/2">
          Ce putem face mai bine?
          <Textarea className="w-full h-20" ref={areaRef} {...bind} />
          <div className="text-sm text-slate-500">
            <p>
              Acest formular este pentru sugestii de îmbunătățire sau probleme
              întâmpinate în folosirea site-ului. Vă rugăm să nu includeți date
              personale în mesaj. Puteți include o adresă de e-mail dacă doriți
              să vă contactăm pentru clarificări.
            </p>
            <p className="mt-2">
              Pentru orice alte probleme vă rugăm să ne contactați la{' '}
              {process.env.NEXT_PUBLIC_CONTACT_EMAIL_ADDRESS && (
                <>
                  <a
                    href={`mailto:${process.env.NEXT_PUBLIC_CONTACT_EMAIL_ADDRESS}`}
                    className="text-primary"
                  >
                    {process.env.NEXT_PUBLIC_CONTACT_EMAIL_ADDRESS}
                  </a>{' '}
                  sau la
                </>
              )}{' '}
              <a href="tel:0256969" className="text-primary">
                0256 969
              </a>
              .
            </p>
          </div>
          <div className="flex gap-4 mt-2">
            <Button primary>Trimite feedback</Button>
            <Button className="w-32" onClick={onCancel}>
              Renunță
            </Button>
          </div>
        </div>
      </form>
    </Container>
  )
}

export const FeedbackForm = () => {
  const [open, setOpen] = useState(false)
  const [sent, setSent] = useState(false)

  const misconfigured = !process.env.NEXT_PUBLIC_FEEDBACK_GRAPHQL_URL

  const sendMessage = async (message: string) => {
    if (typeof message == 'undefined' || message.trim() == '') return

    postFeedback({ message: message })
    setSent(true)
  }

  const sendVote = async () => {
    postVote()
    setSent(true)
  }

  if (sent) return <Container>Mulțumim pentru feedback!</Container>

  if (open)
    return <Form onSubmit={sendMessage} onCancel={() => setOpen(false)} />

  return (
    <>
      <Container>
        <h3 className="m-0 text-base font-semibold">
          Este utilă această pagină?
        </h3>
        <div className="flex gap-4 grow sm:justify-end">
          <Button
            className="min-w-[5rem]"
            onClick={sendVote}
            disabled={misconfigured}
          >
            Da
          </Button>
          <Button
            className="min-w-[5rem] justify-self-end"
            onClick={() => setOpen(true)}
            disabled={misconfigured}
          >
            Ce putem face mai bine?
          </Button>
        </div>
      </Container>
      {misconfigured && (
        <ErrorLabel>
          NEXT_PUBLIC_FEEDBACK_GRAPHQL_URL not configured in .env
        </ErrorLabel>
      )}
    </>
  )
}
