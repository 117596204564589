import clsx from 'clsx'
import React from 'react'
import { Label } from './Label'

type InputProps = JSX.IntrinsicElements['input'] & {
  label?: string
  error: any
  placeholder?: any
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      label = null,
      required = false,
      error = null,
      placeholder = null,
      className = 'col-span-6 sm:col-span-1',
      ...params
    },
    ref
  ) => (
    <div className={clsx('group', className)}>
      <Label text={label} forId={name} required={required} error={error} />
      <input
        {...params}
        name={name}
        id={name}
        ref={ref}
        className={clsx(
          'group block w-full px-3 py-2 mt-1 text-lg border font-medium rounded-md focus:outline-none focus:ring-4 focus:ring-primary-light focus:border-primary-dark placeholder-normal',
          error ? 'ring-red-600 ring-1 border-red-600' : 'border-slate-400'
        )}
      />
      <div className="mt-2 text-sm group-focus-within:text-slate-700 text-slate-400">
        {error ? (
          <span className="text-red-600">{error.message}</span>
        ) : (
          placeholder
        )}
      </div>
    </div>
  )
)
