import clsx from 'clsx'
import React, { forwardRef } from 'react'
import { focusInputClasses } from '../utils/css-classes'

type TextareaProps = JSX.IntrinsicElements['textarea']

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ children, className, ...props }, ref) => (
    <textarea
      className={clsx(
        'px-2 py-1 border rounded-md border-slate-300',
        focusInputClasses,
        className
      )}
      ref={ref}
      {...props}
    >
      {children}
    </textarea>
  )
)
