import clsx from 'clsx'
import React, { ReactNode } from 'react'
import styles from './Breadcrumbs.module.css'

export const Breadcrumbs = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div
        className={clsx(
          styles.breadcrumbs,
          'breadcrumbs flex py-2 text-sm sm:text-base flex-nowrap'
        )}
      >
        {children}
      </div>
    </>
  )
}
