import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { useState } from 'react'

export const MainMenu = ({
  menu,
  activeMenuItem,
  onChange,
  mobileMenuOpen,
  onMobileMenuOpen,
}) => {
  return (
    <>
      <ol className="flex items-center sm:hidden">
        <li
          className={clsx(
            'flex h-[58px] mt-[2px] px-3 items-center gap-x-1 font-semibold cursor-pointer',
            mobileMenuOpen && 'bg-primary-dark'
          )}
          onClick={() => {
            onMobileMenuOpen && onMobileMenuOpen(!mobileMenuOpen)
          }}
        >
          Meniu{' '}
          {mobileMenuOpen ? (
            <ChevronUpIcon className="w-5 h-5" />
          ) : (
            <ChevronDownIcon className="w-5 h-5" />
          )}
        </li>
        <li className="ml-0.5 hover:bg-primary-dark flex items-stretch mt-1 border-b-2 border-primary">
          <a href="#" className="px-5 flex items-center">
            <svg
              className="w-5 h-5 pb-0.5 text-white"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
            >
              <path
                fill="currentColor"
                d="M18.932 16.845a10.206 10.206 0 0 0 2.087-6.261A10.5 10.5 0 0 0 10.584 0a10.584 10.584 0 0 0 0 21.168 9.9 9.9 0 0 0 6.261-2.087l4.472 4.472a1.441 1.441 0 0 0 2.087 0 1.441 1.441 0 0 0 0-2.087zm-8.348 1.193a7.508 7.508 0 0 1-7.6-7.453 7.6 7.6 0 0 1 15.2 0 7.508 7.508 0 0 1-7.6 7.452z"
              ></path>
            </svg>
          </a>
        </li>
      </ol>
      <ol className="sm:flex items-stretch font-semibold tracking-tight hidden">
        {!process.env.NEXT_PUBLIC_EVPERS &&
          !process.env.NEXT_PUBLIC_SERVICII &&
          menu?.map((menuItem) => (
            <li
              key={menuItem.title}
              className={clsx(
                'ml-0.5 flex items-stretch mt-1',
                activeMenuItem?.title == menuItem.title &&
                  'bg-primary-dark border-primary-dark border-b-2',
                activeMenuItem?.title != menuItem.title &&
                  'hover:bg-primary-dark border-primary mb-0.5'
              )}
            >
              <a
                href="#"
                onClick={() => onChange && onChange(menuItem)}
                className="no-underline px-4 flex items-center gap-x-2"
              >
                {menuItem.title}
                {activeMenuItem?.title == menuItem.title ? (
                  <ChevronUpIcon className="w-5 h-5" />
                ) : (
                  <ChevronDownIcon className="w-5 h-5" />
                )}
              </a>
            </li>
          ))}
        <li className="ml-0.5 hover:bg-primary-dark flex items-stretch mt-1 mb-0.5 border-primary">
          <a href="/cont" className="no-underline px-4 flex items-center">
            Contul meu
          </a>
        </li>
        {!process.env.NEXT_PUBLIC_EVPERS &&
          !process.env.NEXT_PUBLIC_SERVICII && (
            <li className="ml-0.5 hover:bg-primary-dark flex items-stretch mt-1 mb-0.5 border-primary">
              <a href="#" className="px-5 flex items-center">
                <svg
                  className="w-5 h-5 pb-0.5 text-white"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path
                    fill="currentColor"
                    d="M18.932 16.845a10.206 10.206 0 0 0 2.087-6.261A10.5 10.5 0 0 0 10.584 0a10.584 10.584 0 0 0 0 21.168 9.9 9.9 0 0 0 6.261-2.087l4.472 4.472a1.441 1.441 0 0 0 2.087 0 1.441 1.441 0 0 0 0-2.087zm-8.348 1.193a7.508 7.508 0 0 1-7.6-7.453 7.6 7.6 0 0 1 15.2 0 7.508 7.508 0 0 1-7.6 7.452z"
                  ></path>
                </svg>
              </a>
            </li>
          )}
      </ol>
    </>
  )
}
