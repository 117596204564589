import clsx from 'clsx'
import React, { ChangeEvent, KeyboardEvent, useState } from 'react'

const focusInputClasses =
  'appearance-none focus:ring-primary-light focus-visible:ring-4 focus:ring-4 focus:ring-offset-0 focus:outline-none focus:border-slate-500 focus:placeholder-white placeholder-slate-500'

export const SearchInput = React.forwardRef<
  HTMLInputElement,
  JSX.IntrinsicElements['input']
>(({ onChange, className, ...props }, ref) => {
  const [closeButton, setCloseButton] = useState(false)
  const [value, setValue] = useState('')

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Escape') {
      clearInput()
      e.preventDefault()
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue((e.target as HTMLInputElement).value)
    setCloseButton((e.target as HTMLInputElement).value != '')
    onChange && onChange(e)
  }

  const clearInput = () => {
    setValue('')
    // onChange && onChange('')
    setCloseButton(false)
  }

  return (
    <div className={clsx('relative text-lg', className)}>
      <div className="absolute inset-y-0 left-0 flex pl-3 pointer-events-none">
        <svg
          className="w-4 h-4 mt-[11px] text-slate-400"
          viewBox="0 0 24 24"
          aria-hidden="true"
          focusable="false"
        >
          <path
            fill="currentColor"
            d="M18.932 16.845a10.206 10.206 0 0 0 2.087-6.261A10.5 10.5 0 0 0 10.584 0a10.584 10.584 0 0 0 0 21.168 9.9 9.9 0 0 0 6.261-2.087l4.472 4.472a1.441 1.441 0 0 0 2.087 0 1.441 1.441 0 0 0 0-2.087zm-8.348 1.193a7.508 7.508 0 0 1-7.6-7.453 7.6 7.6 0 0 1 15.2 0 7.508 7.508 0 0 1-7.6 7.452z"
          ></path>
        </svg>
      </div>
      <input
        onKeyUp={onKeyDown}
        onChange={handleChange}
        value={value}
        className={clsx(
          'px-2 py-1 border border-slate-300 h-10 rounded-full w-full pr-8 pl-9',
          focusInputClasses,
          className
        )}
        ref={ref}
        {...props}
      />
      {closeButton && (
        <div
          className="absolute top-0 right-0 p-2 cursor-pointer group"
          onClick={clearInput}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4 mt-1 mr-0.5 stroke-slate-400 group-hover:stroke-black"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      )}
    </div>
  )
})
