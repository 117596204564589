const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './stories/**/*.{js,ts,jsx,tsx}',
    './utils/**/*.{js,ts}',
  ],
  theme: {
    screens: {
      xs: '475px',
      ...defaultTheme.screens,
    },
    extend: {
      colors: {
        primary: '#003995',
        link: '#005bbc',
        'primary-hover': '#308bec',
        'primary-light': '#95add3',
        'primary-dark': '#002F7A',
      },
      gridTemplateColumns: {
        '1-2': '1fr 2fr',
        '2-1': '2fr 1fr',
      },
    },
    fontFamily: {
      sans: ['Inter', ...defaultTheme.fontFamily.sans],
    },
  },
}
