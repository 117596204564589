import React from 'react'
import clsx from 'clsx'

export const Label = ({ text, forId, required = false, error = false }) => {
  if (!text) return

  return (
    <label
      htmlFor={forId}
      className={clsx(
        'block font-medium',
        error ? 'text-red-600' : 'text-slate-700'
      )}
    >
      {text[0].toUpperCase()}
      {text.substring(1)}
      {required && <sup className="text-primary-hover">*</sup>}
    </label>
  )
}
