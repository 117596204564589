import { MenuItems } from '@/generated/graphql'
import clsx from 'clsx'
import Link from 'next/link'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { MainMenu } from './MainMenu'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline'
import { Wrap } from './Wrap'
import { useRouter } from 'next/router'
import { H1 } from './H1'
import { MENU_ICONS } from './Icon'

const Title = ({ children }: { children: ReactNode }) => <H1>{children}</H1>

const Description = ({ children }: { children: ReactNode }) => (
  <p className="mb-2 sm:mb-4 text-lg leading-6 max-w-[60ch]">{children}</p>
)

type HeaderProps = {
  menu: MenuItems[]
  children?: ReactNode
}

type HeaderSubcomponents = {
  Title?: ReactElement
  Description?: ReactElement
}

export const Header = ({
  menu,
  children,
}: HeaderProps & HeaderSubcomponents) => {
  const router = useRouter()
  const [activeMenuItem, setActiveMenu] = useState<MenuItems | null>(null)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [mobileActiveState, setMobileActiveState] = useState({})

  const childItems = activeMenuItem?.children.length
    ? activeMenuItem?.children
    : activeMenuItem?.service_categories

  const isActiveMobileMenu = (title) =>
    title in mobileActiveState && mobileActiveState[title] === true

  const childMobileItems = (menuItem) =>
    menuItem?.children.length
      ? menuItem?.children
      : menuItem?.service_categories

  useEffect(() => {
    const handleStop = () => {
      setMobileMenuOpen(false)
      setActiveMenu(null)
    }

    router.events.on('routeChangeComplete', handleStop)

    return () => {
      router.events.off('routeChangeComplete', handleStop)
    }
  }, [router])

  return (
    <header className={clsx('text-white bg-primary', children && 'pb-2')}>
      <Wrap>
        <nav className="flex justify-between">
          <Link
            href="https://primariatm.ro"
            prefetch={false}
            className="flex items-center py-3 pr-2.5 no-underline"
          >
            <>
              <img
                width={419}
                height={595}
                className="w-6 sm:w-8 mr-1"
                src="https://d37frk053gnamp.cloudfront.net/img/pmt-logo-white.png"
                alt="Stema Timișoarei"
              />
              <div className="pt-1 pl-1 text-sm font-semibold leading-4 text-white tracking-tight">
                Primăria
                <br />
                Timișoara
              </div>
            </>
          </Link>

          {process.env.NEXT_PUBLIC_INFOCENTRU ||
          process.env.NEXT_PUBLIC_TINERI ? (
            <></>
          ) : (
            <MainMenu
              menu={menu}
              activeMenuItem={activeMenuItem}
              mobileMenuOpen={mobileMenuOpen}
              onMobileMenuOpen={(mobileMenuOpen) =>
                setMobileMenuOpen(mobileMenuOpen)
              }
              onChange={(menuItem: MenuItems) => {
                if (activeMenuItem?.title == menuItem.title) {
                  setActiveMenu(null)
                  return
                }

                setActiveMenu(menuItem)
              }}
            />
          )}
        </nav>
      </Wrap>
      {activeMenuItem && (
        <div className="bg-primary-dark pt-4 py-6 hidden sm:block">
          <Wrap>
            <div className="lg:grid lg:grid-cols-3">
              <div className="text-2xl pt-4 max-w-[12ch] hidden lg:block">
                {activeMenuItem.description}
                {activeMenuItem.title == 'Servicii' && (
                  <div className="mt-4">
                    <Link
                      href="/servicii"
                      className="text-sm flex items-center no-underline hover:underline"
                    >
                      <>
                        Vezi toate serviciile{' '}
                        <ChevronRightIcon className="w-4 h-4" />
                      </>
                    </Link>
                  </div>
                )}
              </div>
              <div className="lg:col-span-2 grid grid-cols-2 mt-1">
                {childItems.map((menuItem) => (
                  <Link
                    href={
                      menuItem.slug
                        ? `/servicii/categorie/${menuItem.slug}`
                        : menuItem.url || '#'
                    }
                    className="no-underline px-4 py-3 hover:bg-primary-darker group"
                  >
                    <span className="flex items-start">
                      <div className="mr-3">
                        {MENU_ICONS[menuItem.icon] || menuItem.icon}
                      </div>
                      <div className="w-full">
                        <div className="font-bold text-lg tracking-tight group-hover:underline leading-5">
                          {menuItem.title || menuItem.name}
                        </div>
                        <div className="text-blue-200 leading-5 mt-1 group-hover:text-blue-100">
                          {menuItem.description}
                        </div>
                      </div>
                    </span>
                  </Link>
                ))}
              </div>
            </div>
          </Wrap>
        </div>
      )}

      {mobileMenuOpen && (
        <ol className="px-3 bg-primary-dark divide-y divide-white/20 sm:hidden">
          {!process.env.NEXT_PUBLIC_EVPERS &&
            !process.env.NEXT_PUBLIC_SERVICII &&
            menu?.map((menuItem) => (
              <li key={menuItem.title}>
                <div
                  onClick={() => {
                    const active = {}
                    active[menuItem.title] = !isActiveMobileMenu(menuItem.title)
                    setMobileActiveState({ ...mobileActiveState, ...active })
                  }}
                  className={clsx(
                    'w-full no-underline flex items-center gap-x-2 text-lg font-semibold tracking-tight cursor-pointer',
                    isActiveMobileMenu(menuItem.title) ? 'pt-4' : 'py-4'
                  )}
                >
                  {menuItem.title}
                  {isActiveMobileMenu(menuItem.title) ? (
                    <ChevronUpIcon className="w-5 h-5" />
                  ) : (
                    <ChevronDownIcon className="w-5 h-5" />
                  )}
                </div>

                {isActiveMobileMenu(menuItem.title) && (
                  <div className="bg-primary-dark my-3">
                    {childMobileItems(menuItem).map((menuItem) => (
                      <Link
                        href={
                          menuItem.slug
                            ? `/servicii/categorie/${menuItem.slug}`
                            : menuItem.url || '#'
                        }
                        className="no-underline flex items-start py-3 gap-3"
                      >
                        {MENU_ICONS[menuItem.icon] && (
                          <div>
                            {MENU_ICONS[menuItem.icon] || menuItem.icon}
                          </div>
                        )}
                        <div>
                          <div className="font-bold text-lg tracking-tight leading-5">
                            {menuItem.title || menuItem.name}
                          </div>
                          <div className="text-blue-200 leading-5 mt-1">
                            {menuItem.description}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </li>
            ))}
          <li className="ml-0.5 flex items-stretch">
            <a
              href="/cont"
              className="w-full no-underline flex items-center text-lg py-3 font-semibold tracking-tight"
            >
              Contul meu
            </a>
          </li>
        </ol>
      )}
      {children && <Wrap className="border-t border-white/20">{children}</Wrap>}
    </header>
  )
}

Header.Title = Title
Header.Description = Description
