import clsx from 'clsx'
import React from 'react'
import {focusInputClasses} from '../utils/css-classes'

type InputProps = JSX.IntrinsicElements['input']

export const TextInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({className, ...props}, ref) => (
    <input
      className={clsx('px-2 py-1 border h-10 rounded-none', focusInputClasses, className)}
      ref={ref}
      type="text"
      {...props}
    />
  )
)
