import React, { ReactNode } from 'react'
// import { styled } from '../stitches.config';

// export const Summary = styled('p', {
//   lineHeight: '$md',
//   fontWeight: 600,
//   letterSpacing: '$tight',

//   '@mobile': {
//     fontSize: '$normal',
//   },
//   '@tablet': {
//     fontSize: '$md',
//   },
// });
export const Summary = ({ children, ...props }: { children: ReactNode }) => (
  <h2 className="pb-4 text-lg max-w-[60ch]" {...props}>
    {children}
  </h2>
)
