import clsx from 'clsx'
import React from 'react'
import { focusInputClasses } from '../utils/css-classes'

type SelectProps = JSX.IntrinsicElements['select']

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ children, className, ...props }, ref) => (
    <select
      className={clsx(
        'flex font-medium items-center h-10 pl-4 border rounded-md appearance-none pr-9 bg-white',
        focusInputClasses,
        className
      )}
      style={{
        backgroundImage:
          "url(\"data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' width='16' viewBox='0 0 24 24' stroke='currentColor'><path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7' /></svg>\")",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right .7em top 50%, 0 0',
      }}
      {...props}
    >
      {children}
    </select>
  )
)
