import clsx from 'clsx'
import React from 'react'
import { overrideTailwindClasses } from 'tailwind-override'

type WrapProps = React.PropsWithChildren<{
  nopadding?: boolean
  className?: string
}>

export const Wrap = ({ children, nopadding = false, className }: WrapProps) => (
  <div className={clsx('sm:px-8', nopadding ? '' : 'px-4')}>
    <div
      className={overrideTailwindClasses(
        clsx('mx-auto lg:max-w-5xl box-content', className)
      )}
    >
      {children}
    </div>
  </div>
)
