import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { overrideTailwindClasses } from 'tailwind-override'

type H2Props = {
  className: string
  children?: ReactNode
}

export const H2 = ({ children, className, ...props }: H2Props) => (
  <h2
    className={overrideTailwindClasses(
      clsx(
        'pt-2 mt-16 mb-6 text-xl font-bold tracking-tight border-t-2 sm:text-2xl border-primary',
        className
      )
    )}
    {...props}
  >
    {children}
  </h2>
)
