export const MENU_ICONS = {
  family_restroom: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 3 40 40"
      fill="white"
    >
      <path d="M30 9.792q-1.292 0-2.208-.917-.917-.917-.917-2.208 0-1.292.917-2.209.916-.916 2.208-.916 1.292 0 2.208.916.917.917.917 2.209 0 1.291-.917 2.208-.916.917-2.208.917Zm-2.208 26.875V22.875q0-1.375-.709-2.479-.708-1.104-1.958-1.688l1.625-4.75q.333-1.041 1.229-1.666.896-.625 2.021-.625t2.021.625q.896.625 1.229 1.666l4.25 12.417h-4.458v10.292Zm-7.084-17.5q-1.041 0-1.77-.729-.73-.73-.73-1.771 0-1.042.73-1.771.729-.729 1.77-.729 1.042 0 1.771.729.729.729.729 1.771 0 1.041-.729 1.771-.729.729-1.771.729ZM9.167 9.792q-1.292 0-2.209-.917-.916-.917-.916-2.208 0-1.292.916-2.209.917-.916 2.209-.916 1.291 0 2.208.916.917.917.917 2.209 0 1.291-.917 2.208-.917.917-2.208.917ZM6.125 36.667V24.708H3.333v-10.25q0-1.166.813-1.979.812-.812 1.979-.812h6.083q1.167 0 1.98.812.812.813.812 1.979v10.25h-2.792v11.959Zm12.208 0v-6.959h-1.958v-6.833q0-.833.604-1.437.604-.605 1.479-.605h4.5q.834 0 1.438.605.604.604.604 1.437v6.833h-1.958v6.959Z" />
    </svg>
  ),
  import_contacts: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 6 40 40"
      fill="white"
    >
      <path d="M23.333 16.208v-2.25q1.375-.583 2.813-.875 1.437-.291 3.021-.291 1.083 0 2.125.166 1.041.167 2.041.417v2.083q-1-.375-2.021-.562-1.02-.188-2.145-.188-1.584 0-3.042.396-1.458.396-2.792 1.104Zm0 9.167v-2.292q1.375-.583 2.813-.854 1.437-.271 3.021-.271 1.083 0 2.125.167 1.041.167 2.041.417v2.083q-1-.375-2.021-.563-1.02-.187-2.145-.187-1.584 0-3.042.375-1.458.375-2.792 1.125Zm0-4.583v-2.25q1.375-.584 2.813-.875 1.437-.292 3.021-.292 1.083 0 2.125.167 1.041.166 2.041.416v2.084q-1-.375-2.021-.563-1.02-.187-2.145-.187-1.584 0-3.042.396-1.458.395-2.792 1.104ZM10.5 27.208q2.125 0 4.167.5 2.041.5 4 1.5V11.875q-1.834-1.167-3.917-1.792-2.083-.625-4.25-.625-1.542 0-3.063.355-1.52.354-2.979.937v17.542q1.334-.542 2.917-.813 1.583-.271 3.125-.271Zm10.958 2q2-1 3.938-1.5 1.937-.5 4.104-.5 1.542 0 3.146.25 1.604.25 2.896.709V10.75q-1.375-.667-2.917-.979-1.542-.313-3.125-.313-2.167 0-4.208.625-2.042.625-3.834 1.792Zm-1.416 4.125q-2.084-1.583-4.521-2.437-2.438-.854-5.021-.854-1.583 0-3.125.396-1.542.395-3.042 1.02-.916.459-1.791-.083-.875-.542-.875-1.583V10.208q0-.541.27-1.041.271-.5.813-.75 1.792-.875 3.75-1.313 1.958-.437 4-.437 2.542 0 4.979.687 2.438.688 4.563 2.063 2.125-1.375 4.52-2.063 2.396-.687 4.938-.687 2.042 0 3.979.437 1.938.438 3.729 1.313.542.25.834.75.291.5.291 1.041v19.584q0 1.083-.895 1.646-.896.562-1.813.02-1.458-.666-3-1.041-1.542-.375-3.125-.375-2.583 0-4.979.875t-4.479 2.416Zm-8.5-13.791Z" />
    </svg>
  ),
  home_work: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 2 40 40"
      fill="white"
    >
      <path d="M28.792 14.375h2.791v-2.792h-2.791Zm0 6.958h2.791v-2.791h-2.791Zm0 6.959h2.791V25.5h-2.791ZM27.25 35v-2.792h8.292V7.792H19.583v3.875l-2.791-1.959V5h21.541v30ZM1.667 35V18.625l11.416-8.167 11.375 8.167V35h-9.333v-8.375h-4.083V35Zm2.791-2.792h3.834v-8.333h9.583v8.333h3.833V20.042l-8.625-6.167-8.625 6.167ZM27.25 17.083Zm-9.375 15.125v-8.333H8.292v8.333-8.333h9.583Z" />
    </svg>
  ),
  payments: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 5 40 40"
      fill="white"
    >
      <path d="M22.792 22.208q-2.084 0-3.542-1.458-1.458-1.458-1.458-3.542 0-2.083 1.458-3.541 1.458-1.459 3.542-1.459 2.083 0 3.541 1.459 1.459 1.458 1.459 3.541 0 2.084-1.459 3.542-1.458 1.458-3.541 1.458ZM10 27.792q-1.167 0-1.979-.813-.813-.812-.813-1.979V9.458q0-1.166.813-1.979.812-.812 1.979-.812h25.542q1.166 0 1.979.812.812.813.812 1.979V25q0 1.167-.812 1.979-.813.813-1.979.813ZM13.875 25h17.792q0-1.625 1.125-2.75t2.75-1.125v-7.792q-1.625 0-2.75-1.125t-1.125-2.75H13.875q0 1.625-1.125 2.75T10 13.333v7.792q1.625 0 2.75 1.125T13.875 25Zm19.458 8.333H4.458q-1.166 0-1.979-.812-.812-.813-.812-1.979V11.667h2.791v18.875h28.875ZM10 25V9.458 25Z" />
    </svg>
  ),
  recycling: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 3 40 40"
      fill="white"
    >
      <path d="m15.333 15.333 3.709-6.125-2.459-4.083q-.5-.833-1.437-.833-.938 0-1.438.833l-4.083 6.792Zm16.125 11.334L27.75 20.5l5.792-3.333 2.666 4.458q.459.708.48 1.604.02.896-.355 1.604-.416.834-1.229 1.334-.812.5-1.771.5Zm-4.791 11.666L20 31.667 26.667 25v3.333h7.916l-2.416 4.834q-.459.833-1.25 1.333-.792.5-1.75.5h-2.5ZM10.542 35q-.834 0-1.521-.438-.688-.437-1.021-1.145-.333-.667-.312-1.396.02-.729.395-1.354L9.5 28.333h7.167V35Zm-4.125-4.75-2.709-5.417q-.375-.75-.354-1.604.021-.854.479-1.604L4.5 20.5l-2.833-1.708 9.125-2.292 2.291 9.167-2.875-1.75ZM28.917 16l-9.125-2.292L22.667 12l-5.209-8.667h5.875q.875 0 1.646.438.771.437 1.229 1.187l2.167 3.625 2.833-1.75Z" />
    </svg>
  ),
  commute: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 6 40 40"
      fill="white"
    >
      <path d="M17.042 32.375v-9.5L19.375 16q.125-.333.437-.583.313-.25.813-.25h12.458q.5 0 .792.229.292.229.417.604l2.375 6.875v9.5q0 .417-.271.687-.271.271-.688.271h-1.041q-.375 0-.667-.271-.292-.27-.292-.687v-1.75h-13.75v1.75q0 .417-.27.687-.271.271-.688.271h-1q-.417 0-.688-.271-.27-.27-.27-.687Zm2.583-10.958h14.458l-1.458-4.292H21.042Zm-.667 1.958v5.333Zm2.709 4.333q.708 0 1.166-.479.459-.479.459-1.187 0-.667-.459-1.146-.458-.479-1.166-.479-.709 0-1.167.479-.458.479-.458 1.146 0 .708.458 1.187.458.479 1.167.479Zm10.375 0q.666 0 1.146-.479.479-.479.479-1.187 0-.667-.479-1.146-.48-.479-1.146-.479-.709 0-1.188.479t-.479 1.146q0 .708.479 1.187.479.479 1.188.479Zm-25.25 5.625v-1.125l2.125-2.166q-2.292 0-3.938-1.438-1.646-1.437-1.646-3.646V11.417q0-2.75 2.271-3.75t8.521-1q6 0 8.375 1.041 2.375 1.042 2.375 3.709v2.375h-2.75v-2.709h-16v10.792h9.5v11.458Zm1.291-6.708q.709 0 1.167-.479.458-.479.458-1.188 0-.666-.458-1.146-.458-.479-1.167-.479-.708 0-1.166.479-.459.48-.459 1.146 0 .709.459 1.188.458.479 1.166.479Zm10.875 2.083h15.75v-5.333h-15.75Z" />
    </svg>
  ),
  park: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 6 40 40"
      fill="white"
    >
      <path d="M9.167 36.667v-2.792h9.458v-7.208H15q-3.458 0-5.896-2.438-2.437-2.437-2.437-5.896 0-2.5 1.375-4.604t3.708-3.062q.375-3.125 2.729-5.229Q16.833 3.333 20 3.333t5.521 2.105q2.354 2.104 2.729 5.229 2.333.958 3.708 3.062t1.375 4.604q0 3.459-2.437 5.896-2.438 2.438-5.896 2.438h-3.625v7.208h9.75v2.792ZM15 23.875h10q2.333 0 3.938-1.604 1.604-1.604 1.604-3.938 0-1.666-.938-3.041-.937-1.375-2.479-2.042l-1.458-.625L25.458 11q-.25-2.083-1.812-3.479Q22.083 6.125 20 6.125t-3.646 1.396Q14.792 8.917 14.542 11l-.209 1.625-1.458.625q-1.542.667-2.479 2.042-.938 1.375-.938 3.041 0 2.292 1.604 3.917 1.605 1.625 3.938 1.625ZM20 15Z" />
    </svg>
  ),
  theater_comedy: (
    <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" fill="white">
      <path d="M32.042 12.042q.583 0 1-.438.416-.437.416-.979 0-.625-.416-1.042-.417-.416-1-.416-.625 0-1.042.416-.417.417-.417 1.042 0 .542.417.979.417.438 1.042.438Zm-7 0q.583 0 1-.438.416-.437.416-.979 0-.625-.416-1.042-.417-.416-1-.416-.625 0-1.042.416-.417.417-.417 1.042 0 .542.417.979.417.438 1.042.438ZM24.125 18h8.75q0-1.417-1.354-2.208Q30.167 15 28.5 15q-1.667 0-3.021.792-1.354.791-1.354 2.208ZM11.5 36.625q-4.083 0-6.958-2.917-2.875-2.916-2.875-6.916V15.5h19.666v11.292q0 4-2.875 6.916-2.875 2.917-6.958 2.917Zm0-2.75q2.917 0 4.979-2.125 2.063-2.125 2.063-4.958v-8.5H4.458v8.5q0 2.833 2.063 4.958 2.062 2.125 4.979 2.125Zm17-9.417q-1.292 0-2.542-.312-1.25-.313-2.375-.896v-3.167q1.042.667 2.292 1.146 1.25.479 2.625.479 2.917 0 4.979-2 2.063-2 2.063-4.833v-8.75H21.375v7.167h-2.792V3.333h19.75v11.542q0 4-2.875 6.792-2.875 2.791-6.958 2.791ZM8.042 24q.583 0 1-.417.416-.416.416-1.041 0-.584-.416-1-.417-.417-1-.417-.625 0-1.042.417-.417.416-.417 1 0 .625.417 1.041.417.417 1.042.417Zm7 0q.625 0 1.041-.417.417-.416.417-1.041 0-.584-.417-1-.416-.417-1.041-.417-.584 0-1 .417-.417.416-.417 1 0 .625.417 1.041.416.417 1 .417Zm-3.459 6.208q1.667 0 3.021-.812 1.354-.813 1.354-2.188h-8.75q0 1.375 1.354 2.188 1.355.812 3.021.812Zm-.041-4.25Zm16.916-11.916Z" />
    </svg>
  ),
  local_hospital: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 3 40 40"
      fill="white"
    >
      <path d="M17.917 28.375h4.166v-6.292h6.292v-4.166h-6.292v-6.292h-4.166v6.292h-6.292v4.166h6.292ZM7.792 35q-1.125 0-1.959-.833Q5 33.333 5 32.208V7.792q0-1.125.833-1.959Q6.667 5 7.792 5h24.416q1.125 0 1.959.833.833.834.833 1.959v24.416q0 1.125-.833 1.959-.834.833-1.959.833Zm0-2.792h24.416V7.792H7.792v24.416Zm0-24.416v24.416V7.792Z" />
    </svg>
  ),
  sports_handball: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 1 40 40"
      fill="white"
    >
      <path d="m14.875 39.792-2.333-1.417 5.208-8.917-3.208-1.916-2.917 4.791-2.375-1.375 9.208-15.25q-1.666-1.583-2.521-3.479-.854-1.896-.854-3.979 0-1.542.334-2.937.333-1.396 1.125-2.896l2.416 1.333q-.583 1.125-.833 2.167-.25 1.041-.25 2.125 0 2.083 1 3.812 1 1.729 3.167 3.021l3.916 2.292q2.459 1.416 3.625 3.708 1.167 2.292 1.167 4.833 0 1.625-.479 3.23Q29.792 30.542 29 32l-2.417-1.375q.667-1.25 1.021-2.437.354-1.188.354-2.438 0-1.5-.5-2.792-.5-1.291-1.5-2.125Zm11.708-25.167q-1.291 0-2.208-.937-.917-.938-.917-2.23 0-1.291.917-2.208.917-.917 2.208-.917 1.292 0 2.209.917.916.917.916 2.208 0 1.292-.916 2.23-.917.937-2.209.937Zm-4-7.958q-1.083 0-1.791-.729-.709-.73-.709-1.771 0-1.084.729-1.792.73-.708 1.771-.708 1.084 0 1.792.729t.708 1.771q0 1.083-.729 1.791-.729.709-1.771.709Z" />
    </svg>
  ),
  pin_drop: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 3 40 40"
      fill="white"
    >
      <path d="M20 27.542q4.25-3.459 6.438-6.625 2.187-3.167 2.187-5.959 0-2.416-.896-4.104-.896-1.687-2.187-2.75-1.292-1.062-2.792-1.521-1.5-.458-2.75-.458t-2.75.458q-1.5.459-2.792 1.521-1.291 1.063-2.187 2.75-.896 1.688-.896 4.104 0 2.792 2.187 5.959Q15.75 24.083 20 27.542Zm0 3.5q-5.75-4.292-8.562-8.271-2.813-3.979-2.813-7.813 0-2.875 1.021-5.041 1.021-2.167 2.666-3.646 1.646-1.479 3.667-2.208Q18 3.333 20 3.333t4.021.73q2.021.729 3.667 2.208 1.645 1.479 2.666 3.646 1.021 2.166 1.021 5.083 0 3.792-2.813 7.771Q25.75 26.75 20 31.042Zm0-13.25q1.25 0 2.146-.896.896-.896.896-2.188 0-1.25-.896-2.145-.896-.896-2.146-.896t-2.146.896q-.896.895-.896 2.145 0 1.292.896 2.188.896.896 2.146.896ZM8.625 36.667v-2.792h22.75v2.792ZM20 14.958Z" />
    </svg>
  ),
  business_center: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 3 40 40"
      fill="white"
    >
      <path d="M6.125 35Q5 35 4.167 34.167q-.834-.834-.834-1.959V12.542q0-1.125.834-1.959Q5 9.75 6.125 9.75h7.5V6.125q0-1.125.813-1.958.812-.834 1.937-.834h7.25q1.125 0 1.937.834.813.833.813 1.958V9.75h7.5q1.125 0 1.958.833.834.834.834 1.959v19.666q0 1.125-.834 1.959Q35 35 33.875 35Zm10.25-25.25h7.25V6.125h-7.25Zm17.5 14.958h-9.667v2.75h-8.333v-2.75h-9.75v7.5h27.75Zm-15.25 0h2.792v-2.791h-2.792Zm-12.5-2.791h9.75v-2.792h8.333v2.792h9.667v-9.375H6.125ZM20 23.292Z" />
    </svg>
  ),
  volunteer_activism: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 2 40 40"
      fill="white"
    >
      <path d="M26.833 21.167q-1.958-1.75-3.729-3.417-1.771-1.667-3.125-3.271-1.354-1.604-2.167-3.104Q17 9.875 17 8.5q0-2.208 1.5-3.708t3.75-1.5q1.25 0 2.458.646 1.209.645 2.125 1.729.917-1.084 2.125-1.729 1.209-.646 2.459-.646 2.25 0 3.75 1.5t1.5 3.708q0 1.375-.813 2.875-.812 1.5-2.166 3.104-1.355 1.604-3.126 3.271-1.77 1.667-3.729 3.417Zm0-3.792q2.667-2.458 4.855-4.875 2.187-2.417 2.187-4 0-1.083-.667-1.771-.666-.687-1.75-.687-.666 0-1.312.312-.646.313-1.229 1.063l-2.084 2.541-2.083-2.541q-.583-.75-1.229-1.063-.646-.312-1.313-.312-1.083 0-1.75.687-.666.688-.666 1.771 0 1.583 2.187 4 2.188 2.417 4.854 4.875ZM23.5 37.458l-12.542-3.583v2.417H1.667V19.208h13l10.541 3.959q1.209.458 2 1.5.792 1.041.792 2.708h4.292q1.833 0 3.104 1.292 1.271 1.291 1.271 3.458v1.25ZM4.458 33.5h3.709V22H4.458Zm18.917 1.042 10.417-3.167q-.209-.708-.604-.979-.396-.271-.896-.271h-8.417q-1.292 0-2.333-.167-1.042-.166-2.084-.5l-3.208-1 .917-2.666 3.125 1q.958.333 1.916.458.959.125 2.917.125 0-.5-.229-.958-.229-.459-.646-.625L14.208 22h-3.25v9ZM8.167 27.75Zm16.958-.375Zm-16.958.375Zm2.791 0Zm15.875-16.042Z" />
    </svg>
  ),
  flash_on: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 4 40 40"
      fill="white"
    >
      <path d="M18.625 13.875V7.792h-6.667V5h16.084v2.792h-6.667v6.083ZM6.667 35V21.958h2.791v1.667h5.834v-5.583h-1.667v-2.75h12.75v2.75h-1.667v5.583h5.834v-1.667h2.791V35h-2.791v-1.667H9.458V35Zm2.791-4.458h21.084v-4.167h-8.584v-8.333h-3.916v8.333H9.458Zm10.542 0Z" />
    </svg>
  ),
  supervised_user_circle: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 6 40 40"
      fill="white"
    >
      <path d="M1.667 33.333v-6.666q0-1.292.895-2.167.896-.875 2.146-.875h5.75q.75 0 1.438.375.687.375 1.146 1 1.208 1.708 3.041 2.667 1.834.958 3.917.958 2.083 0 3.938-.958Q25.792 26.708 27 25q.5-.625 1.146-1 .646-.375 1.396-.375h5.75q1.25 0 2.146.875.895.875.895 2.167v6.666H27.208V28.75q-1.458 1.292-3.312 1.958-1.854.667-3.896.667-2 0-3.854-.687-1.854-.688-3.354-1.938v4.583ZM20 26.375q-1.5 0-2.875-.687-1.375-.688-2.25-1.938-.667-1-1.646-1.583-.979-.584-2.146-.709 1.125-1.375 3.834-2.104 2.708-.729 5.083-.729t5.104.729q2.729.729 3.813 2.104-1.125.125-2.105.709-.979.583-1.645 1.583-.875 1.25-2.25 1.938-1.375.687-2.917.687Zm-13.333-5.25q-1.959 0-3.334-1.396t-1.375-3.354q0-1.958 1.375-3.333 1.375-1.375 3.334-1.375 2 0 3.354 1.375t1.354 3.333q0 1.958-1.354 3.354-1.354 1.396-3.354 1.396Zm26.666 0q-1.958 0-3.333-1.396-1.375-1.396-1.375-3.354 0-1.958 1.375-3.333 1.375-1.375 3.333-1.375 2 0 3.355 1.375 1.354 1.375 1.354 3.333 0 1.958-1.354 3.354-1.355 1.396-3.355 1.396Zm-13.333-5q-1.958 0-3.333-1.396-1.375-1.396-1.375-3.354 0-1.958 1.375-3.333Q18.042 6.667 20 6.667q2 0 3.354 1.375t1.354 3.333q0 1.958-1.354 3.354Q22 16.125 20 16.125Z" />
    </svg>
  ),
  shield: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="40"
      width="40"
      viewBox="0 2 40 40"
      fill="white"
    >
      <path d="M20 36.667q-5.833-1.459-9.583-6.729-3.75-5.271-3.75-11.605v-10l13.333-5 13.333 5v10q0 6.334-3.75 11.605-3.75 5.27-9.583 6.729Zm0-2.875q4.292-1.417 7.125-5.23Q29.958 24.75 30.458 20H20V6.333L9.458 10.25v8.917q0 .333.084.833H20Z" />
    </svg>
  ),
}

const ICONS = {
  email: (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
    />
  ),
  phone: (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
    />
  ),
  link: (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
    />
  ),
  insert_drive_file: (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
    />
  ),
}

export const Icon = ({ name, className = 'w-10 h-10' }) => {
  !ICONS[name] && console.log('Missing icon:', name)
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      {ICONS[name]}
    </svg>
  )
}
