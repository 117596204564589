import React from 'react'
import { Wrap } from '@/design-system'
import { overrideTailwindClasses } from 'tailwind-override'
import clsx from 'clsx'

type PageProps = React.PropsWithChildren<{
  preview?: boolean
  beta?: boolean
  dark?: boolean
  className?: string
}>

export const Page = ({
  children,
  beta = false,
  preview = false,
  dark = false,
  className = '',
}: PageProps) => (
  <>
    <div
      className={overrideTailwindClasses(
        clsx('flex flex-col h-screen', dark && 'dark-bg', className)
      )}
    >
      {(preview || beta) && (
        <Wrap className="relative">
          <div className="absolute top-0 right-0 flex space-x-2">
            {preview && (
              <a
                href="/api/preview?cancel"
                className="flex flex-col items-center px-4 py-1 text-xs font-semibold text-white no-underline bg-red-600 hover:underline"
              >
                Exit Preview
              </a>
            )}
            {beta && (
              <div className="inline-block px-4 py-1 text-xs font-semibold bg-amber-300 text-slate-900">
                BETA
              </div>
            )}
          </div>
        </Wrap>
      )}
      {children}
    </div>
  </>
)
