import clsx from 'clsx'
import { useState } from 'react'
import React from 'react'
import { FieldError } from 'react-hook-form'

type CheckboxProps = JSX.IntrinsicElements['input'] & {
  label?: string
  error: FieldError
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ name, children, className, error = null, ...params }, ref) => {
    const [checked, setChecked] = useState(false)
    return (
      <div className={`flex mt-4 ${className}`}>
        <input
          id={name}
          name={name}
          ref={ref}
          onChange={(e) => setChecked(e.target.checked)}
          aria-describedby="candidates-description"
          type="checkbox"
          className={clsx(
            'border-2 rounded-md w-7 h-7 text-primary hover:text-primary-dark hover:border-primary-dark focus:ring-4 focus:ring-primary-light focus:ring-offset-0 cursor-pointer focus:outline-none',
            error ? 'border-red-600 focus:border-slate-400' : 'border-slate-400'
          )}
          {...params}
        />
        <label
          htmlFor={name}
          className={clsx(
            'pl-4 mt-0.5 cursor-pointer',
            error ? 'text-red-600' : checked ? 'text-black' : 'text-slate-700'
          )}
        >
          {children}
        </label>
      </div>
    )
  }
)
