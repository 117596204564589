import { Wrap } from '@/design-system'
import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { overrideTailwindClasses } from 'tailwind-override'

type MainProps = {
  className?: string
  dark?: boolean
  children: ReactNode
}

export const Main = ({ children, dark, className }: MainProps) => (
  <main
    className={overrideTailwindClasses(
      clsx('grow', dark && 'bg-slate-200 pb-8', className)
    )}
  >
    <Wrap>{children}</Wrap>
  </main>
)
