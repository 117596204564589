import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { overrideTailwindClasses } from 'tailwind-override'

type H1Props = {
  children?: ReactNode
  className?: string
}

export const H1 = ({ children, className, ...props }: H1Props) => (
  <h1
    className={overrideTailwindClasses(
      clsx(
        'mb-3 mt-1 text-3xl font-bold tracking-tight sm:mb-4 sm:text-5xl max-w-[21ch]',
        className
      )
    )}
    {...props}
  >
    {children}
  </h1>
)
