import React from 'react'
import {Button} from './Button'
import {FeedbackForm} from './FeedbackForm'
import {Wrap} from './Wrap'

export const Footer = () => (
  <>
    <div>
      <Wrap nopadding>
        <FeedbackForm />
      </Wrap>
    </div>
    <footer className="py-5 bg-slate-900 text-slate-300">
      <Wrap>
        <div className="flex flex-col items-start xs:flex-row">
          <address className="not-italic leading-6">
            <strong>Primăria Municipiului Timișoara</strong>
            <br />
            B-dul C.D. Loga, nr. 1<br />
            300030 Timișoara
            <br />
            <br />
            Telefon: <a href="tel:0256969">0256 969</a>
            <br />
            Email: <a href="mailto:primariatm@primariatm.ro">primariatm@primariatm.ro</a>
          </address>

          <div className="xs:flex-1"></div>

          <div className="mt-6 xs:mt-0 xs:self-start">
            <a
              href="#"
              className="inline-flex items-center text-sm no-underline hover:underline hover:text-white text-slate-300"
            >
              Mergi sus
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-3 h-3 ml-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 10l7-7m0 0l7 7m-7-7v18"
                />
              </svg>
            </a>
          </div>
        </div>
      </Wrap>
    </footer>
  </>
)
