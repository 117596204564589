import { CheckIcon } from '@heroicons/react/outline'
import clsx from 'clsx'

export const TimelineStep = ({
  title,
  step = 1,
  subtitle = null,
  children,
  complete = false,
  last = false,
  className = '',
  hideNumbers = false,
}) => {
  const iconColor = complete
    ? 'bg-green-600'
    : 'bg-primary font-bold text-white'
  const lineColor = complete ? 'bg-green-700 opacity-40' : 'bg-slate-200'

  return (
    <div className={className}>
      <div className="relative pb-4">
        {!last && !hideNumbers && (
          <span
            className={
              lineColor +
              ' hidden md:block absolute top-4 left-4 -ml-px h-full w-0.5'
            }
            aria-hidden="true"
          />
        )}
        <div className="relative flex">
          {!hideNumbers && (
            <div className="hidden md:block">
              <span
                className={
                  iconColor +
                  ' flex items-center text-sm justify-center w-8 h-8 rounded-full ring-8 ring-white transition duration-150 ease-in-out'
                }
              >
                {complete ? (
                  <CheckIcon
                    className="w-5 h-5 text-white"
                    aria-hidden="true"
                  />
                ) : (
                  <span>{step}</span>
                )}
              </span>
            </div>
          )}

          <div
            className={clsx(
              'flex-1 md:grid md:gap-2',
              hideNumbers ? 'md:grid-cols-5' : 'md:grid-cols-4'
            )}
          >
            <div
              className={clsx(
                'md:pr-1',
                hideNumbers || 'px-4 sm:px-0 md:px-6 md:pl-3'
              )}
            >
              <h3
                className={clsx(
                  'mt-4 font-bold leading-5 tracking-tight text-black md:mt-1.5',
                  hideNumbers ? 'text-lg' : 'text-2xl'
                )}
              >
                {title}
              </h3>
              {subtitle && <p className="mt-1 text-slate-500">{subtitle}</p>}
            </div>
            <div
              className={clsx(
                'mt-5 md:mt-0',
                hideNumbers ? 'md:col-span-4' : 'md:col-span-3'
              )}
            >
              <div className="overflow-hidden">
                <div
                  className={clsx(
                    'px-4 py-5 bg-primary-bg sm:p-6',
                    step == 1 && 'md:border-t-4 border-primary',
                    last && 'md:border-b-4 border-primary'
                  )}
                >
                  <div className="grid grid-cols-12 gap-x-2 gap-y-4 lg:gap-x-4">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
